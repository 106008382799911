import {SET_FLAGS, SET_PROVIDERS} from './mutation-types'
import {actionWrapper} from './helpers'
import {executeRequest} from '@/utils/http-clients/base'
import {baseApiExternalUrl} from '@/constants/config'

export default {
  getUser: async () => executeRequest('get', 'user', {withCredentials: true}),
  acceptAgreement: async ({commit}) => actionWrapper({
    commit,
    action: async () => {
      return executeRequest(
        'post',
        'user/privacy-license-agreement',
        {data: {privacyAgreementAccepted: true}, withCredentials: true})
    }
  }),
  authenticate: async ({commit}, data) => actionWrapper({
    commit,
    action: async () => executeRequest('post', 'authenticate', {data, withCredentials: true})
  }),
  externalAuthenticate: async ({commit}, params) => actionWrapper({
    commit,
    action: async () => executeRequest(
      'get',
      'callback',
      {
        baseURL: baseApiExternalUrl,
        withCredentials: true,
        params
      }
    )
  }),
  register: async ({commit}, {data, token}) => actionWrapper({
    commit,
    action: async () => {
      const headers = {'Authorization': token}
      return executeRequest('post', `user/register`, {headers, data})
    }
  }),
  signOut: async ({commit}, logoutId) => actionWrapper({
    commit,
    action: async () => {
      return executeRequest(
        'get',
        'authenticate/logout',
        {
          params: {logoutId},
          withCredentials: true
        }
      )
    }
  }),
  getFlags: async ({commit}) => actionWrapper({
    commit,
    action: async () => {
      const flags = await executeRequest('get', `config`)
      commit(SET_FLAGS, flags)
      return flags
    }
  }),
  getProviders: async ({commit}) => actionWrapper({
    commit,
    action: async () => {
      const providers = await executeRequest('get', `external/providers`)
      console.log({providers})
      commit(SET_PROVIDERS, providers)
      return providers
    }
  }),
  getError: async ({commit}, errorId) => actionWrapper({
    commit,
    action: async () => executeRequest('get', `authenticate/error`, {params: {errorId}})
  }),
  resetPassword: async ({commit}, data) => actionWrapper({
    commit,
    action: async () => executeRequest('post', `user/password/request-reset`, {data})
  }),
  resendEmail: async ({commit}, {redirectUri, email}) => actionWrapper({
    commit,
    action: async () => executeRequest(
      'get',
      `user/resend-email`,
      {params: {redirectUri, email}}
    )
  }),
  updatePassword: async ({commit}, data) => actionWrapper({
    commit,
    action: async () => executeRequest('post', `user/password/reset`, {data})
  }),
  confirmResetPassword: async ({commit}, {email, token}) => actionWrapper({
    commit,
    action: async () => executeRequest('get', `user/password/confirm-reset`, {params: {email, token}})
  }),
  confirmEmail: async ({commit}, {email, token}) => actionWrapper({
    commit,
    action: async () => executeRequest('get', `user/confirm-email`, {params: {email, token}})
  })
}
