<template lang="pug">
  v-form(
    v-if="showForm"
    key="form"
    ref="register"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
  )
    v-text-field(
      v-model="registerForm.email"
      label="email"
      :rules="rules.email"
      :loading="loading"
      :readonly="registrationRequired"
      dark
      dense
      filled
      required
    )
    v-text-field(
      v-model="registerForm.password"
      label="choose a password"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="rules.password"
      :type="showPass ? 'text' : 'password'"
      :loading="loading"
      hint="At least 8 characters"
      dark
      filled
      dense
      @click:append="showPass = !showPass"
    )
    v-text-field(
      v-model="registerForm.confirmPassword"
      label="confirm password"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="passwordConfirmationRules"
      :type="showPass ? 'text' : 'password'"
      :loading="loading"
      dark
      filled
      dense
      @click:append="showPass = !showPass"
    )
    v-checkbox.primary-checkbox.mt-0(
      v-if="legalConditionAcceptanceEnabled"
      v-model="registerForm.privacyAgreementAccepted"
      :rules="[rules.agree]"
      color="primary"
      dense
      dark
    )
      template(#label)
        .text-body-2.mt-1.text-primary.font-weight-600
          span I agree with REGDATA's
          | {{' '}}
          a.base-link.underline(
            :href="privacyLink"
            target="_blank"
            @click.stop=""
          ) privacy licence agreement
    v-btn.mt-3(
      type="submit"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) sign up
    div.mt-1(v-if="showLoginLink")
      router-link.base-link.text-body-2.font-weight-600(:to="{name: 'login'}") I already have an account
  activation-email-sent-block(
    v-else-if="showSuccessBlock"
    key="success"
  )
  v-alert(
    v-else-if="showInviteErrorBlock"
    type="error"
    key="error"
    text
  ) {{errorText}}
</template>

<script>
  import {agree, emailRules, getPasswordRules, namedRequired} from '@/utils/validation'
  import {Notify} from '@/utils/notify'
  import ActivationEmailSentBlock from '@/components/ActivationEmailSentBlock'
  import {privacyLink} from '@/constants/config'
  import {mapActions, mapGetters, mapState} from 'vuex'

  export default {
    components: {ActivationEmailSentBlock},
    metaInfo: {
      title: 'Sign Up'
    },
    data: () => ({
      invalidToken: false,
      errorText: 'Not possible to register without invitation',
      valid: true,
      isSuccess: false,
      showPass: false,
      registrationRequired: false,
      registerForm: {
        email: '',
        privacyAgreementAccepted: false,
        password: '',
        confirmPassword: ''
      },
      privacyLink,
      rules: {
        agree,
        password: getPasswordRules(),
        email: emailRules
      }
    }),
    computed: {
      ...mapState(['loading']),
      ...mapGetters([
        'legalConditionAcceptanceEnabled',
        'requireRegistrationByInvite'
      ]),
      showLoginLink () {
        return !(this.requireRegistrationByInvite || this.registrationRequired)
      },
      showForm () {
        return !this.showSuccessBlock && !this.showInviteErrorBlock
      },
      showSuccessBlock () {
        return this.isSuccess
      },
      showInviteErrorBlock () {
        return !this.showSuccessBlock && this.requireRegistrationByInvite && (this.invalidToken || !this.token)
      },
      redirectUri () {
        return this.$route.query.redirectUri
      },
      redirectUriParams () {
        return !!this.redirectUri ? Object.fromEntries(new URL(this.redirectUri).searchParams) : {}
      },
      processedRedirectUri () {
        if (!this.redirectUri) return this.redirectUri
        const url = new URL(this.redirectUri)
        url.searchParams.delete('requireRegistration')
        return url.href
      },
      token () {
        const {token} = this.redirectUriParams
        return token && `Bearer ${token}`
      },
      passwordConfirmationRules () {
        return [
          namedRequired('Confirm password'),
          () => this.registerForm.password === this.registerForm.confirmPassword || 'Password must match'
        ]
      }
    },
    methods: {
      ...mapActions([
        'register'
      ]),
      parseUrl () {
        const {email, requireRegistration} = this.redirectUriParams
        this.registrationRequired = requireRegistration === 'true'
        this.registerForm.email = email
      },
      async submit () {
        if (this.$refs.register.validate()) await this.registerUser()
      },
      async registerUser () {
        const {registerForm, processedRedirectUri, token, legalConditionAcceptanceEnabled} = this
        const {privacyAgreementAccepted, ...registerFormWithoutAgreement} = registerForm
        const formData = legalConditionAcceptanceEnabled ? registerForm : registerFormWithoutAgreement
        const data = {...formData, redirectUri: processedRedirectUri}

        try {
          const {emailConfirmed} = await this.register({data, token})
          if (emailConfirmed) {
            await this.$router.push({name: 'login', query: {redirectUri: processedRedirectUri}})
          }
          this.isSuccess = true
        } catch (error) {
          this.invalidToken = error.status === 401
          if (this.invalidToken) Notify({title: this.errorText})
        }
      }
    },
    async created () {
      this.parseUrl()
    }
  }
</script>

<style lang="stylus">
</style>
