import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthPage from '@/views/AuthPage'
import LoginForm from '@/components/forms/LoginForm'
import ErrorForm from '@/components/forms/ErrorForm'
import LogoutForm from '@/components/forms/LogoutForm'
import RegisterForm from '@/components/forms/RegisterForm'
import ResetPasswordForm from '@/components/forms/ResetPasswordForm'
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm'
import ValidationEmail from '@/components/forms/ValidationEmail'
import {basePath} from '@/constants/config'
import AcceptAgreementForm from '@/components/forms/AcceptAgreementForm'
import ExternalCallback from '@/components/forms/ExternalCallback'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'authPage',
    redirect: {name: 'login'},
    component: AuthPage,
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginForm
      },
      {
        path: 'error/:text?',
        name: 'error',
        component: ErrorForm
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterForm
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: ResetPasswordForm
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: ForgotPasswordForm
      },
      {
        path: 'validate',
        name: 'validate',
        component: ValidationEmail
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: AcceptAgreementForm
      },
      {
        path: 'logout',
        name: 'logout',
        component: LogoutForm
      },
      {
        path: 'callback',
        name: 'callback',
        component: ExternalCallback
      }
    ]
  }
]

const router = new VueRouter({
  base: basePath,
  mode: 'history',
  routes
})

const setRedirectUri = (to, from, next) => {
  const redirectUri = to.query.redirectUri || from.query.redirectUri
  const {name, query, params} = to

  if (query.redirectUri || !redirectUri) {
    if (to.name === 'login' && query.redirectUri) {
      const { requireRegistration } = !!query.redirectUri ? Object.fromEntries(new URL(query.redirectUri).searchParams) : {}
      if (requireRegistration === 'true') {
         next({
          name: 'register',
          query: to.query
        })
        return
      }
    }

    next()
    return
  }

  next({name, params, query: {...query, redirectUri}})
}

router.beforeEach(setRedirectUri)

export default router
