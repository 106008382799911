import axios from 'axios'
import {setDefaultInterceptors} from '@/utils/http-clients/interceptors'
import {baseApiUrl} from '@/constants/config'
import {Notify} from '@/utils/notify'

const config = {
  baseURL: baseApiUrl,
  json: true
}

const httpClient = axios.create(config)

const authInterceptor = async config => {
  return config
}

const errorNotificationHandler = ({status, data, error}) => {
  if (status !== 401) Notify({title: error})
}

const ErrorHandler = ({status, error, data}) => {
  errorNotificationHandler({status, error, data})
}

const errorHandlerInterceptor = error => {
  const errorResponse = error?.response || {}
  const errorData = {...errorResponse.data, status: errorResponse.status}

  ErrorHandler(errorData)

  return Promise.reject(errorData)
}

setDefaultInterceptors(httpClient)
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(config => config, errorHandlerInterceptor)

httpClient.interceptors.response.use(response => response, errorHandlerInterceptor)


export const executeRequest = async (method, endpoint, config = {}) => {
  try {
    const {data} = await httpClient({method, url: `/${endpoint}`, ...config})
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default httpClient
