<template lang="pug">
  v-img(:lazy-src="logo" :src="logo" contain)
</template>

<script>
  import logo from '@/assets/img/logo-white.svg'

  export default {
    components: {},
    data: () => ({
      logo
    }),
    computed: {},
    methods: {}
  }
</script>

<style scoped lang="stylus">
</style>
