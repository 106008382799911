<template lang="pug">
  .text-center
    p.text-success.d-flex.align-center.justify-center.font-weight-600
      v-icon(color="success" small).mr-1 mdi-check
      | Activation email sent
    v-alert(
      type="info"
      tile
      text
    ).text-center Almost done! Please check your email and click on the verification link we just sent you.
    p.text-default-2 You'll be able to sign in once your account is verified, which should only take a couple of minutes.
</template>

<script>
  export default {
    data: () => ({}),
    computed: {},
    methods: {}
  }
</script>

<style lang="stylus">
</style>
