import {SET_LOADING} from './mutation-types'

export const actionWrapper = async ({action, commit, errorHandler}) => {
  try {
    if (commit) commit(SET_LOADING, true)
    return await action()
  } catch (error) {
    return errorHandler ? errorHandler(error) : Promise.reject(error)
  } finally {
    if (commit) commit(SET_LOADING, false)
  }
}
