import {baseApiUrl} from '@/constants/config'

export default {
  legalConditionAcceptanceEnabled: ({flags: {legalConditionAcceptance}}) => legalConditionAcceptance.enabled,
  requireRegistrationByInvite: ({flags: {requireRegistrationByInvite}}) => requireRegistrationByInvite,
  signInSignUp: ({flags: {signInSignUp}}) => signInSignUp,
  providersAreEmpty: ({providers}) => providers.length === 0,
  genExternalAuthUrl: ({providers}, {providersAreEmpty}) => (returnUrl, token) => {
    if (!providersAreEmpty) {
      const {scheme} = providers[0]
      const tokenParam = token ? `&token=${token}` : ''
      return `${baseApiUrl}/external/challenge?scheme=${scheme}&returnUrl=${returnUrl}${tokenParam}`
    }
  },
  externalOnly: (state, {signInSignUp}) => signInSignUp === 'External_External'
}
