import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
      options: {
        customProperties: true
      },
    themes: {
      light: {
        primary: '#7a634e',
        secondary: '#2a2f3b',
        accent: '#28666e',
        error: '#ff505c',
        info: '#50d8d7',
        success: '#b5b944',
        warning: '#FFC107'
      }
    }
  }
})
