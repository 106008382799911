import Vue from 'vue'

export const Notify = ({group = 'app', type = 'error', position = 'center', title = '', duration = 6000}) => {
  return new Vue().$notify({
    group,
    type,
    position,
    title,
    duration: type === 'error' ? -1 : duration
  })
}
