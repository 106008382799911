import isArray from 'lodash/isArray'

export const required = v => !!v || 'Field is required'
export const agree = v => !!v || 'You must agree to continue!'
export const namedRequired = field => v => !!v || `${field} is required`
export const maxCharacters = max => (v = '') => v.length <= max || `Max ${max} characters`
export const minCharacters = min => (v = '') => v.length >= min || `At least ${min} characters`
export const atLeastOneUppercase = v => /[A-Z]+/.test(v) || `At least one uppercase character`
export const atLeastOneLowercase = v => /[a-z]+/.test(v) || `At least one lowercase character`
export const shouldContainNumbers = field => v => /[0-9]+/.test(v) || `${field} should contain numbers`
export const shouldContainSpecSymbols = field => v => /[#$^+=!*()@%&]+/.test(v) || `${field} should contain spec character`

export const emailRules = [
  v => !!v || 'Email is required',
  v => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(v) || 'Invalid email address'
]

export const getPasswordRules = (fieldName = 'Password') => [
  namedRequired(fieldName),
  // shouldContainNumbers(fieldName),
  // shouldContainSpecSymbols(fieldName),
  // atLeastOneUppercase,
  // atLeastOneLowercase,
  minCharacters(8)
]

export const phoneNumber = v => (
  // /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
  v.length >= 15 || 'Phone number must be valid'
)

export const isJsonString = str => {
  try {
    return isArray(JSON.parse(str)) || 'Invalid JSON'
  } catch (e) {
    return 'Invalid JSON'
  }
}
