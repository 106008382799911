<template lang="pug">
  v-form(
    v-if="!isSuccess"
    v-model="valid"
    @submit.prevent="submit"
    ref="forgotForm"
    lazy-validation
  )
    v-text-field(
      v-model="forgotForm.email"
      :rules="emailRules"
      :loading="loading"
      label="email"
      dark
      dense
      filled
      required
    )
    v-btn.mt-3(
      :loading="loading"
      type="submit"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) Reset password
    div.mt-1
      router-link.base-link.text-body-2.font-weight-600(:to="{name: 'login'}") Back to sign in
  .text-center(v-else)
    p.text-success.d-flex.align-center.justify-center.font-weight-600
      v-icon(color="success" small).mr-1 mdi-check
      | Confirmation email sent
    v-alert(
      type="info"
      tile
      text
    ).text-center Please check your email and follow the instructions we just sent you.
</template>

<script>
  import {emailRules, required} from '@/utils/validation'
  import {mapActions, mapState} from 'vuex'

  export default {
    metaInfo: {
      title: 'Reset Password'
    },
    data: () => ({
      valid: true,
      showPass: false,
      isSuccess: false,
      forgotForm: {
        email: ''
      },
      rules: {
        required
      },
      emailRules
    }),
    computed: {
      ...mapState(['loading'])
    },
    methods: {
      ...mapActions([
        'resetPassword'
      ]),
      async submit () {
        if (this.$refs.forgotForm.validate()) await this.requestReset()
      },
      async requestReset () {
        const data = {
          email: this.forgotForm.email,
          redirectUri: this.$route.query.redirectUri
        }
        await this.resetPassword(data)
        this.isSuccess = true
      }
    }
  }
</script>

<style scoped lang="stylus">
</style>
