<template lang="pug">
  v-form(
    ref="resetPasswordForm"
    v-model="valid"
    @submit.prevent="submit"
    lazy-validation
  )
    v-text-field(
      v-model="resetPasswordForm.newPassword"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="rules.password"
      :type="showPass ? 'text' : 'password'"
      :loading="loading"
      @click:append="showPass = !showPass"
      label="choose a new password"
      hint="At least 8 characters"
      dark
      filled
      dense
    )
    v-text-field(
      v-model="resetPasswordForm.confirmPassword"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="passwordConfirmationRules"
      :type="showPass ? 'text' : 'password'"
      :loading="loading"
      @click:append="showPass = !showPass"
      label="confirm password"
      dark
      filled
      dense
    )
    v-btn.mt-3(
      :loading="loading"
      type="submit"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) Reset password
    div.mt-1
      router-link.base-link.text-body-2.font-weight-600(:to="{name: 'login'}") Back to sign in
</template>

<script>
  import {getPasswordRules, namedRequired} from '@/utils/validation'
  import {Notify} from '@/utils/notify'
  import {mapActions, mapState} from 'vuex'

  export default {
    metaInfo: {
      title: 'Reset Password'
    },
    data: () => ({
      valid: true,
      showPass: false,
      resetPasswordForm: {
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        password: getPasswordRules()
      }
    }),
    computed: {
      ...mapState(['loading']),
      passwordConfirmationRules () {
        return [
          namedRequired('Confirm password'),
          () => this.resetPasswordForm.newPassword === this.resetPasswordForm.confirmPassword || 'Password must match'
        ]
      }
    },
    methods: {
      ...mapActions([
        'updatePassword',
        'confirmResetPassword'
      ]),
      async submit () {
        if (this.$refs.resetPasswordForm.validate()) await this.resetPassword()
      },
      async validateToken () {
        const {email, token} = this.$route.query

        try {
          await this.confirmResetPassword({email, token})
        } catch (error) {
          await this.$router.push({name: 'login'}).catch(() => {})
        }
      },
      async resetPassword () {
        const {email, token} = this.$route.query
        const {newPassword, confirmPassword} = this.resetPasswordForm
        const data = {email, token, newPassword, confirmPassword}

        await this.updatePassword(data)

        Notify({type: 'success', title: 'Password has been updated'})
        // TODO implement without error catching
        await this.$router.push({name: 'login'}).catch(() => {})
      }
    },
    created () {
      this.validateToken()
    }
  }
</script>

<style scoped lang="stylus">
</style>
