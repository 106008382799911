import {SET_FLAGS, SET_LOADING, SET_PROVIDERS} from './mutation-types'

export default {
  [SET_LOADING] (state, value) {
    state.loading = value
  },
  [SET_FLAGS] (state, flags) {
    state.flags = flags
  },
  [SET_PROVIDERS] (state, providers) {
    state.providers = providers
  }
}
