<template lang="pug">
  .auth-page.d-flex.align-center
    v-container.pb-md-12
      v-row.pb-4(justify="center")
        v-col(xl="4" lg="5" md="7" sm="9" xs="12")
          base-logo
      v-row(justify="center").pb-md-12
        v-col(xl="3" lg="4" md="6" sm="8" xs="11")
          router-view(v-if="!loading && !hideView")
</template>

<script>
  import BaseLogo from '@/components/base/BaseLogo'
  import LoginForm from '@/components/forms/LoginForm'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    components: {
      LoginForm,
      BaseLogo
    },
    data: () => ({
      loading: true,
      hideView: false
    }),
    computed: {
      ...mapGetters(['externalOnly', 'providersAreEmpty', 'genExternalAuthUrl']),
      isExternal () {
        const {name} = this.$route
        return !this.internalForce &&
          !!this.redirectUri &&
          this.externalOnly &&
          (name === 'login' || name === 'register')
      },
      internalForce () {
        return this.redirectUriParams.internal === 'true'
      },
      redirectUriParams () {
        return !!this.redirectUri ? Object.fromEntries(new URL(this.redirectUri).searchParams) : {}
      },
      token () {
        const {token} = this.redirectUriParams
        return token
      },
      redirectUri () {
        return this.$route.query.redirectUri
      }
    },
    watch: {
      $route (to, from) {
        if ((to.name === 'login' || to.name === 'register') && this.isExternal) {
          this.handleExternalSignInSignUp()
        }
      }
    },
    methods: {
      ...mapActions([
        'getFlags',
        'getUser',
        'getProviders'
      ]),
      async externalSignInSignUp () {
        try {
          await this.getUser()
        } catch (error) {
          await this.handleExternalSignInSignUp()
        }
      },
      async handleEmptyProviders () {
        try {
          await this.$router.push({name: 'error', params: {text: 'External providers not found'}})
        } catch (error) {
        }
      },
      async handleExternalSignInSignUp () {
        this.hideView = true
        await this.getProviders()
        if (this.providersAreEmpty) {
          await this.handleEmptyProviders()
          this.hideView = false
        } else {
          this.tryExternalRedirect()
        }
      },
      tryExternalRedirect () {
        if (this.redirectUri && window) {
          window.location.href = this.genExternalAuthUrl(window.encodeURIComponent(this.redirectUri), this.token)
        }
        return !!this.redirectUri
      }
    },
    async mounted () {
      try {
        this.loading = true
        await this.getFlags()
        if (this.isExternal) {
          await this.externalSignInSignUp()
        }
      } finally {
        this.loading = false
      }
    }
  }
</script>

<style scoped lang="stylus">
  .auth-page
    position relative
    width 100%
    height 100%
    background $default
</style>
