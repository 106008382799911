<template lang="pug">
  v-form(
    v-if="!isAuthenticated && !nonConfirmedEmail"
    v-model="valid"
    @submit.prevent="submit"
    ref="login"
    lazy-validation
  )
    v-text-field(
      v-model="loginForm.email"
      :rules="rules.emailRules"
      :loading="loading"
      label="email"
      dark
      dense
      filled
      required
    )
    v-text-field(
      v-model="loginForm.password"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required]"
      :type="showPass ? 'text' : 'password'"
      :loading="loading"
      @click:append="showPass = !showPass"
      hint="At least 8 characters"
      label="password"
      dark
      filled
      dense
    )
    .text-right.forgot-password
      router-link.base-link.text-body-2.font-weight-600.text-default-2(:to="{name: 'forgot'}") I forgot my password
    v-btn.mt-6(
      :loading="loading"
      type="submit"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) SIGN IN
    .mt-1(v-if="!requireRegistrationByInvite")
      router-link.base-link.text-body-2.font-weight-600(:to="{name: 'register'}") I do not have an account yet
  authenticated-form(
    v-else-if="isAuthenticated"
    :user-name="userName"
  )
  non-confirmed-form(
    v-else
    :email="loginForm.email"
    :redirect-uri="redirectUri"
    @back-to-login="nonConfirmedEmail = false"
  )
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import {emailRules, required} from '@/utils/validation'
  import {Notify} from '@/utils/notify'
  import NonConfirmedForm from '@/components/forms/NonConfirmedForm'
  import AuthenticatedForm from '@/components/forms/AuthenticatedForm'

  export default {
    components: {AuthenticatedForm, NonConfirmedForm},
    metaInfo: {
      title: 'Sign In'
    },
    data: () => ({
      valid: true,
      showPass: false,
      userName: 'Guest',
      nonConfirmedEmail: false,
      isAuthenticated: false,
      loginForm: {
        email: '',
        password: ''
      },
      rules: {
        required,
        emailRules
      }
    }),
    computed: {
      ...mapState(['loading']),
      ...mapGetters([
        'legalConditionAcceptanceEnabled',
        'requireRegistrationByInvite'
      ]),
      redirectUri () {
        return this.$route.query.redirectUri
      },
      redirectUriParams () {
        return !!this.redirectUri ? Object.fromEntries(new URL(this.redirectUri).searchParams) : {}
      }
    },
    methods: {
      ...mapActions([
        'getUser',
        'authenticate'
      ]),
      async parseUrl () {
        const {email, requireRegistration} = this.redirectUriParams
        if (requireRegistration === 'true') {
          await this.$router.push({name: 'register'})
        } else {
          this.loginForm.email = email
        }
      },
      async submit () {
        if (this.$refs.login.validate()) await this.login()
      },
      async checkUserWithRedirect () {
        try {
          const {userName, privacyAgreementAccepted} = await this.getUser()
          if (this.legalConditionAcceptanceEnabled && !privacyAgreementAccepted) {
            await this.$router.push({name: 'agreement'})
          } else if (!this.tryRedirect()) {
            this.isAuthenticated = true
            this.userName = userName
          }
        } catch (error) {
          console.log(error)
          this.isAuthenticated = false
        }
      },
      async login () {
        const data = {
          userName: this.loginForm.email,
          password: this.loginForm.password,
          redirectUri: this.redirectUri
        }

        try {
          await this.authenticate(data)
          await this.checkUserWithRedirect()
        } catch (error) {
          if (error.status === 401) {
            Notify({title: 'Invalid password or email'})
          }
          this.nonConfirmedEmail = error.error === 'EMAIL_NOT_CONFIRMED'
        }
      },
      tryRedirect () {
        if (this.redirectUri && window) window.location.href = window.decodeURIComponent(this.redirectUri)
        return !!this.redirectUri
      }
    },
    async created () {
      await this.parseUrl()
      await this.checkUserWithRedirect()
    }
  }
</script>

<style scoped lang="stylus">
  .forgot-password
    position relative
    margin-top -22px
    z-index 1
</style>
