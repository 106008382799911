<template lang="pug">
  v-form(
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
  )
    v-alert(
      type="error"
      tile
      text
    )
      p.mb-1.font-weight-medium {{alertText}}
    v-checkbox(
      v-model="agree"
      :rules="rules"
      color="primary"
      dense
      dark
    )
      template(#label)
        .text-body-2.mt-1.text-primary.font-weight-600
          span I agree with REGDATA's
          | {{' '}}
          a.base-link.underline(
            :href="privacyLink"
            target="_blank"
            @click.stop=""
          ) privacy licence agreement
    v-btn.mt-1(
      type="submit"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) Accept
</template>

<script>
  import {agree} from '@/utils/validation'
  import {Notify} from '@/utils/notify'
  import {privacyLink} from '@/constants/config'
  import {mapActions} from 'vuex'

  export default {
    metaInfo: {
      title: 'User agreement'
    },
    data: () => ({
      alertText: `REGDATA's privacy licence agreement must be accepted in order to continue using REGDATA services.`,
      agree: false,
      valid: true,
      rules: [agree],
      privacyLink
    }),
    computed: {
      redirectUri () {
        return this.$route.query.redirectUri
      }
    },
    methods: {
      ...mapActions([
        'acceptAgreement'
      ]),
      async submit () {
        if (this.$refs.form.validate()) await this.accept()
      },
      async redirect () {
        if (this.redirectUri && window) {
          window.location.href = window.decodeURIComponent(this.redirectUri)
        } else {
          await this.$router.push({name: 'login'})
        }
      },
      async accept () {
        await this.acceptAgreement()
        Notify({
          type: 'success',
          title: `REGDATA's privacy licence agreement accepted`
        })
        await this.redirect()
      }
    },
    created () {}
  }
</script>

<style lang="stylus">
</style>
