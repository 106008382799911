<template lang="pug">
  div
    v-alert(
      type="info"
      tile
      text
    ) User {{userName}} already signed in
    v-btn(
      :to="{name: 'logout'}"
      color="primary"
      width="100%"
      link
      tile
      large
      depressed
    ) Sign out
</template>

<script>
  export default {
    props: {
      userName: {
        type: String
      }
    },
    data: () => ({}),
    methods: {},
    created () {}
  }
</script>

<style scoped lang="stylus">
</style>
