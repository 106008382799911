<template lang="pug">
  .logout-from
    v-progress-circular.d-flex.ma-auto.mb-12.mt-10(
      color="white"
      :width="2"
      :size="30"
      indeterminate
    )
    iframe.logout-iframe(
      v-if="signOutIFrameUrl"
      :src="signOutIFrameUrl"
      @load="redirect"
      width="0"
      height="0"
    )
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    metaInfo: {
      title: 'Sign out'
    },
    data: () => ({
      signOutIFrameUrl: null,
      postLogoutRedirectUri: null
    }),
    computed: {
      ...mapState(['loading'])
    },
    methods: {
      ...mapActions([
        'signOut'
      ]),
      async logout () {
        const {logoutId} = this.$route.query
        const {postLogoutRedirectUri, signOutIFrameUrl} = await this.signOut(logoutId)

        this.postLogoutRedirectUri = postLogoutRedirectUri
        this.signOutIFrameUrl = signOutIFrameUrl

        if (!signOutIFrameUrl) await this.redirect()
      },
      async redirect () {
        if (!this.postLogoutRedirectUri) {
          await this.$router.push({name: 'login'})
        } else {
          window.location.href = this.postLogoutRedirectUri
        }
      }
    },
    async created () {
      await this.logout()
    }
  }
</script>

<style scoped lang="stylus">
  .logout-iframe
    display none
</style>
