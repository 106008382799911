<template lang="pug">
  v-app(id="app")
    base-notification
    router-view
</template>

<script>
  import BaseNotification from '@/components/base/BaseNotification'
  export default {
    name: 'App',
    components: {BaseNotification},
    metaInfo: {
      title: 'RPS REGDATA',
      titleTemplate: '%s - RPS REGDATA'
    },
    data: () => ({})
  }
</script>
<style lang="stylus">
  @import 'assets/stylus/variables.styl'
  #app
    background $white
    font-family 'Montserrat', sans-serif

</style>
