let serverConfig
if (process.browser) {
  try {
    const res = new XMLHttpRequest()
    res.open('GET', `${window.__API_URL__}config/frontend`, false)
    res.send(null)
    serverConfig = {
      baseApiUrl: window.__API_URL__,
      ...JSON.parse(res.responseText)
    }
  } catch (error) {
    const msg = `Can't connect to the server. Try to refresh the page later. <br/>
    Impossible de se connecter au serveur. Essayez de rafraîchir la page plus tard.`

    document.body.innerHTML = msg
    document.body.style.padding = '20px'
    document.body.style.fontWeight = 'bold'
    document.body.style.color = 'red'
    throw error
  }
}

export const basePath = process.env.VUE_APP_BASE_PATH || '/'
export const baseApiUrl = serverConfig.baseApiUrl
export const baseApiExternalUrl = serverConfig.baseApiExternalUrl
export const privacyLink = serverConfig.privacyUrl
