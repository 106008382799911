<template lang="pug">
  .error-from(v-if="!resent && !loading")
    v-alert(
      type="error"
      tile
      text
    ) {{errorText}}
    v-btn.mb-4(
      @click="submit"
      color="accent"
      width="100%"
      link
      tile
      large
      depressed
    ) Send me a new verification link
    v-btn(
      @click="$emit('back-to-login')"
      color="primary"
      width="100%"
      link
      tile
      large
      depressed
    ) Back to sign in
  v-progress-circular.d-flex.ma-auto.mb-12.mt-10(
    v-else-if="loading"
    color="white"
    :width="2"
    :size="30"
    indeterminate
  )
  activation-email-sent-block(v-else)
</template>

<script>
  import ActivationEmailSentBlock from '@/components/ActivationEmailSentBlock'
  import {mapActions, mapState} from 'vuex'
  import {Notify} from '@/utils/notify'

  export default {
    components: {ActivationEmailSentBlock},
    props: {
      email: {
        type: String
      },
      redirectUri: {
        type: String
      }
    },
    data: () => ({
      resent: false,
      errorText: 'Your email address has not been verified. Validate your email address in order to unlock your account. Click here to get a new verification link'
    }),
    computed: {
      ...mapState(['loading'])
    },
    methods: {
      ...mapActions([
        'resendEmail'
      ]),
      async submit () {
        const { redirectUri, email } = this
        try {
          await this.resendEmail({redirectUri, email})

          Notify({
            type: 'success',
            title: 'A new verification link has been sent to your email address'
          })
          this.resent = true
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
</script>

<style scoped lang="stylus">
</style>
