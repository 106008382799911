export const genAuthInterceptor = (config, token, tokenType) => {
  if (token && tokenType) config.headers.Authorization = `${tokenType} ${token}`

  return config
}

export const startTimeInterceptor = config => ({...config, metadata: {startTime: new Date()}})

export const addDurationInterceptor = (resOrErr, type = 'success') => {
  resOrErr.config.metadata.endTime = new Date()
  resOrErr.duration = resOrErr.config.metadata.endTime - resOrErr.config.metadata.startTime

  return type === 'success' ? resOrErr : Promise.reject(resOrErr)
}

export const loggerInterceptor = config => config

export const setDefaultInterceptors = httpClient => {
  httpClient.interceptors.request.use(startTimeInterceptor)
  httpClient.interceptors.request.use(loggerInterceptor)

  httpClient.interceptors.response.use(
    response => addDurationInterceptor(response, 'success'),
    error => addDurationInterceptor(error, 'error')
  )
}
