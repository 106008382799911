<template lang="pug">
  div(v-if="!resent && !loading")
    v-alert(
      type="warning"
      tile
      text
    ) {{alertText}}
    v-btn.mb-4(
      v-if="!emailAlreadyConfirmed"
      @click="submit"
      color="accent"
      width="100%"
      link
      tile
      large
      depressed
    ) Send me a new verification link
    v-btn(
      v-else
      :to="{ name: 'login' }"
      color="primary"
      width="100%"
      tile
      large
      depressed
    ) Back to sign in
  v-progress-circular.d-flex.ma-auto.mb-12.mt-10(
    v-else-if="loading"
    color="white"
    :width="2"
    :size="30"
    indeterminate
  )
  activation-email-sent-block(v-else)
</template>

<script>
  import {Notify} from '@/utils/notify'
  import ActivationEmailSentBlock from '@/components/ActivationEmailSentBlock'
  import {mapActions, mapState} from 'vuex'

  export default {
    components: {ActivationEmailSentBlock},
    metaInfo: {
      title: 'Email Validation'
    },
    data: () => ({
      resent: false,
      emailAlreadyConfirmed: false,
      isSuccess: false
    }),
    computed: {
      ...mapState(['loading']),
      origin () {
        return this.redirectUriParams.origin
      },
      redirectUri () {
        return this.$route.query.redirectUri
      },
      redirectUriParams () {
        return !!this.redirectUri ? Object.fromEntries(new URL(this.redirectUri).searchParams) : {}
      },
      alertText () {
        return this.emailAlreadyConfirmed
          ? 'Your email address has already been confirmed. Go back to sign in page and try again.'
          : 'Your verification link has expired. Click here to get a new verification link.'
      }
    },
    methods: {
      ...mapActions([
        'resendEmail',
        'confirmEmail'
      ]),
      async submit () {
        const {redirectUri, email} = this.$route.query
        await this.resendEmail({redirectUri, email})
        this.resent = true
      },
      async validateEmail () {
        const {email, token} = this.$route.query

        try {
          await this.confirmEmail({email, token})
          Notify({type: 'success', title: 'Your email address has been validated. You can sign in'})
          await this.$router.push({name: 'login'})
        } catch (error) {
          this.emailAlreadyConfirmed = error.error === 'EMAIL_ALREADY_CONFIRMED'
        }
      }
    },
    async created () {
      console.log('redirectUriParams', this.redirectUriParams)
      await this.validateEmail()
    }
  }
</script>

<style scoped lang="stylus">
</style>
