import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VisibilityPlugin from './plugins/visibility'
import VueMeta from 'vue-meta'
import Notifications from 'vue-notification'
import '@/assets/stylus/index.styl'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(VisibilityPlugin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
