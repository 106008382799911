<template lang="pug">
  notifications(
    :group="group"
    position="top center"
    classes="app-notification"
  )
    template(#body="{item: {type, title}, close}")
      v-alert(
        :type="type"
        border="left"
        dense
        tile
        dismissible
        @input="close"
      )
        span.alert-text {{ title }}
</template>
<script>
  export default {
    props: {
      group: {
        type: String,
        default: 'app'
      }
    }
  }
</script>
<style lang="stylus">
  .alert-text
    word-break break-word
</style>
