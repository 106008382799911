<template lang="pug">
  authenticated-form(
    v-if="isAuthenticated"
    :user-name="userName"
  )
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import {Notify} from '@/utils/notify'
  import AuthenticatedForm from '@/components/forms/AuthenticatedForm'

  export default {
    components: {AuthenticatedForm},
    metaInfo: {
      title: 'External Sign In'
    },
    data: () => ({
      userName: 'Guest',
      isAuthenticated: false
    }),
    computed: {
      ...mapState(['loading']),
      ...mapGetters(['legalConditionAcceptanceEnabled']),
      queryParams () {
        return this.$route.query
      }
    },
    methods: {
      ...mapActions([
        'getUser',
        'externalAuthenticate'
      ]),
      async checkUserWithRedirect (redirectUri) {
        try {
          const {userName, privacyAgreementAccepted} = await this.getUser()
          if (this.legalConditionAcceptanceEnabled && !privacyAgreementAccepted) {
            await this.$router.push({name: 'agreement', query: {redirectUri}})
          } else if (!this.tryRedirect(redirectUri)) {
            this.isAuthenticated = true
            this.userName = userName
          }
        } catch (error) {
          console.log(error)
        }
      },
      async login () {
        try {
          const {returnUrl: redirectUri} = await this.externalAuthenticate(this.queryParams)
          console.log({redirectUri})
          await this.checkUserWithRedirect(redirectUri)
        } catch (error) {
          await this.$router.push({
            name: 'error',
            params: {text: error.error || 'Authentication failed'}
          })
        }
      },
      tryRedirect (redirectUri) {
        if (redirectUri && window) window.location.href = window.decodeURIComponent(redirectUri)
        return !!redirectUri
      }
    },
    async created () {
      await this.login()
    }
  }
</script>

<style scoped lang="stylus">
</style>
