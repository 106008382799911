<template lang="pug">
  .error-from
    v-alert(
      v-if="!loading"
      type="error"
      text
    ) {{errorText}}
    v-progress-circular.d-flex.ma-auto.mb-12.mt-10(
      v-else
      color="white"
      :width="2"
      :size="30"
      indeterminate
    )
    v-btn(
      :to="{name: 'login', query: {redirectUri}}"
      color="primary"
      width="100%"
      link
      tile
      large
      depressed
    ) Back to sign in
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    metaInfo: {
      title: 'Sign in Error'
    },
    data: () => ({
      errorText: '',
      redirectUri: ''
    }),
    computed: {
      ...mapState(['loading']),
      errorFromUrl () {
        return this.$route.params.text
      },
      errorId () {
        return this.$route.query.errorId
      }
    },
    methods: {
      ...mapActions([
        'getError'
      ]),
      async getErrorInfo () {
        if (this.errorFromUrl) {
          this.errorText = this.errorFromUrl
        }
        if (this.errorId) {
          const {errorDescription, redirectUri, error} = await this.getError(this.errorId)
          this.errorText = errorDescription || error
          this.redirectUri = redirectUri
        }
      }
    },
    async created () {
      await this.getErrorInfo()
    }
  }
</script>

<style scoped lang="stylus">
</style>
